(function (global) {
  var preloader = document?.querySelector('.preloader');
  var body = document?.querySelector('body');
  // if preloader not present => abort
  if (!preloader) {
    return;
  }
  // disables scrollbar
  body.style.overflow = 'hidden';
  // main.ts call this function once the app is boostrapped
  global.appBootstrap = function () {
    endCounter();
  };
  function endCounter() {
    // animate preloader hiding
    setTimeout(removePreloader, 100);
    // retore scrollbar
    body.style.overflow = '';
  }
  function removePreloader() {
    preloader.className = 'preloader-hidden';
  }
})(window);
